/**
 * = Input groups
 */

.input-group {
	border-radius: $input-border-radius;
	transition: $transition-base;
	.form-control {
		&:focus{
			border-color:$input-focus-border-color;
			transition: $transition-base;
		}
	}
}

.input-group-text {
	font-size: $font-size-sm;
	transition: $input-transition;
}



